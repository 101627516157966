<script setup>
    import { onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue';

    const props = defineProps({
        "title": { type: String },
        "description": { type: String },
        "dataObject": { type: Object },
    });

    const emit = defineEmits(['addItem'])

    const accordionBody = ref(null);
    const showBody = ref(false);
    const uuid = ref(null);

    const showAccordionBody = () => {
        showBody.value = true;
    };

    const hideAccordionBody = () => {
        showBody.value = false;
    };

    onMounted(() => {
        accordionBody.value.addEventListener('show.bs.collapse', showAccordionBody)
        accordionBody.value.addEventListener('hide.bs.collapse', hideAccordionBody)
        uuid.value = self.crypto.randomUUID();
    });

    onBeforeUnmount(() => {
        accordionBody.value.removeEventListener('show.bs.collapse', showAccordionBody)
        accordionBody.value.removeEventListener('hide.bs.collapse', hideAccordionBody)
    });

</script>

<template>
    <div class="accordion" :id="`accordion${uuid}`">
        <div class="accordion-item border-0">
            <h2 class="accordion-header" :id="`heading${uuid}`">
                <button class="accordion-button justify-content-start collapsed" data-bs-toggle="collapse" :data-bs-target="`#id${uuid}`">
                    <div class="d-grid my-2">
                        <div class="fw-semibold" v-if="title || $slots.title">
                            <span v-if="title">{{ title }}</span>
                            <slot name="title"/>
                        </div>

                        <div class="mt-2 text-truncate" style="font-weight: 400; color: #929397; padding-right: 20px;" v-if="!showBody && (description || $slots.description)">
                            <span v-if="description">
                                {{ description }}
                            </span>
                            <slot name="description"/>
                        </div>

                        <slot />
                    </div>
                </button>
            </h2>

            <div :id="`id${uuid}`" class="accordion-collapse collapse" :data-bs-parent="`#accordion${uuid}`" ref="accordionBody">
                <div class="accordion-body p-0">
                    <template v-if="dataObject">
                        <div class="list-group">
                            <template v-if="dataObject.data.length == 0">
                                <div class="d-flex justify-content-center align-items-center" style="height: 4.0em; font-weight: 400; color: #929397;">
                                    {{ $t('No results found') }}
                                </div>
                            </template>

                            <template v-else>
                                <slot name="items" />
                            </template>

                            <slot name="footer" />
                        </div>
                    </template>

                    <template v-else>
                        <ul class="list-group">
                            <slot name="items" />
                            <slot name="footer" />
                        </ul>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
</style>